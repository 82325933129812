import { Teacher } from '../../types/Teacher';
import { TeacherInfo } from '../../types/TeacherInfo';

type TeacherType = Teacher | TeacherInfo;

export function teacherAdapter<T extends TeacherType>(teacher: T): T {
  return {
    ...teacher,
    last_name: teacher.last_name[0] + '.'
  } as T;
}
