import { Institution } from '../../types/Institution';

const fetchInstitutions = async (): Promise<Institution[]> => {
  const institutionsUrl = `${process.env.REACT_APP_ESTUDI_API_URL}/estudi/web/institutions`;

  try {
    const response = await fetch(institutionsUrl);

    return await response.json();
  } catch (error) {
    console.error(error);

    return Promise.reject();
  }
};

export const institutionsQueryFunction = () => ({
  queryKey: ['institutions'],
  queryFn: async (): Promise<Institution[]> => fetchInstitutions()
});
