import { Form, useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import Select from 'react-select';
import './Search.css';
import { Course } from '../../types/Course';
import { Institution } from '../../types/Institution';
import { useTeacherSearchContext } from '../../providers/TeacherSearchProvider';
import { useEffect } from 'react';
import { trackClickButtonEvent, trackClickEvent } from '../../utils/functions/trackEvent';

const Search = () => {
  const {
    courses,
    institutions,
    institution: institutionParam
  } = useLoaderData() as {
    courses: Course[] | undefined;
    institutions: Institution[];
    institution: Institution;
  };
  const { setInstitution, institution, setCourse, course } = useTeacherSearchContext();
  const navigate = useNavigate();
  const submit = useSubmit();

  useEffect(() => {
    setInstitution(institutionParam);
  }, [institutionParam]);

  const handleCourseChange = (selectedOption: any) => {
    setCourse(selectedOption);
  };

  const handleInstitutionChange = (selectedOption: any) => {
    setCourse(undefined);
    setInstitution(selectedOption);
    navigate(`/institutions/${selectedOption.value}`);
  };

  const handleSubmit = () => {
    if (!institution || !course) {
      return;
    }

    const formData = new FormData();

    submit(formData);
  };

  const handleClickSearch = () => {
    trackClickButtonEvent('search');
  };

  return (
    <div className="search">
      <h1>estudi</h1>
      <p className="searchTitle">
        Encontrá{' '}
        <span className="highlight-container">
          <span className="highlight">tu profe</span>
        </span>{' '}
        ideal
      </p>
      <Form className="searchFilters" method="get" action={`/teachers`} onSubmit={handleSubmit}>
        <Select
          name="institutionId"
          options={institutions}
          value={institution ?? null}
          placeholder="Universidad"
          className="select"
          onChange={handleInstitutionChange}
        />
        <Select
          name="courseId"
          options={courses}
          placeholder="Materia"
          value={course ?? null}
          className="select"
          onChange={handleCourseChange}
          isDisabled={!courses || courses.length < 1}
        />
        <button
          className="searchButton"
          disabled={!course || !institution}
          onClick={handleClickSearch}>
          Buscar
        </button>
      </Form>
      <p className="noInstitution">
        ¿No encontraste tu universidad o materia?
        <br />
        <a href="/request" onClick={() => trackClickEvent('anchor', 'go_to_teacher_request_page')}>
          Escribinos
        </a>{' '}
        para encontrar tu profe igual.
      </p>
      <p className="searchLink">
        Para saber más entra a nuestra <a href="/help">página de ayuda</a>.
      </p>
    </div>
  );
};

export default Search;
