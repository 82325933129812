import { Institution } from '../types/Institution';
import { Course } from '../types/Course';
import { QueryClient } from 'react-query';
import { ensureQuery } from '../utils/functions/ensureQuery';
import { EstudiApiClient } from '../types/EstudiApiClient';
import { Teacher } from '../types/Teacher';
import { institutionsQueryFunction } from './queries/institutionsQueryFunction';
import { institutionsCoursesQueryFunction } from './queries/institutionsCoursesQueryFunction';
import { teachersQueryFunction } from './queries/teachersQueryFunction';

export class EstudiApiQueryClient implements EstudiApiClient {
  private readonly queryClient: QueryClient;

  constructor(queryClient: QueryClient) {
    this.queryClient = queryClient;
  }

  public async getInstitutionById(params: { institutionId: string }): Promise<Institution | null> {
    //FixMe: when the API exposes the get by id, change this implementation
    return this.getInstitutions().then(
      (institutions) =>
        institutions.find((institution) => institution.value === params.institutionId) ?? null
    );
  }

  public async getInstitutions(): Promise<Institution[]> {
    const institutionsQuery = institutionsQueryFunction();

    return ensureQuery(this.queryClient, institutionsQuery);
  }

  public async getCourseById(params: {
    institutionId: string;
    courseId: string;
  }): Promise<Course | null> {
    //FixMe: when the API exposes the get by id, change this implementation
    return this.getCourses(params).then(
      (courses) => courses.find((course) => course.value === params.courseId) ?? null
    );
  }

  public async getCourses(params: { institutionId: string }): Promise<Course[]> {
    const coursesQuery = institutionsCoursesQueryFunction(params.institutionId);

    return ensureQuery(this.queryClient, coursesQuery);
  }

  public async getTeachers(params: {
    institutionId: string;
    courseId: string;
  }): Promise<Teacher[]> {
    const teachersQuery = teachersQueryFunction(params.institutionId, params.courseId);

    return ensureQuery(this.queryClient, teachersQuery);
  }
}
