import { EstudiApiClient } from '../../types/EstudiApiClient';
import { redirect } from 'react-router-dom';

export const loader =
  (estudiClient: EstudiApiClient) =>
  async ({ request }: { request: any }) => {
    const url = new URL(request.url);
    const institutionId = url.searchParams.get('institutionId');
    const courseId = url.searchParams.get('courseId');

    const course = await estudiClient.getCourseById({
      institutionId: institutionId!,
      courseId: courseId!
    });

    const institution = await estudiClient.getInstitutionById({ institutionId: institutionId! });

    if (!institution || !course) {
      return redirect('/');
    }

    return {
      course,
      teachers: await estudiClient.getTeachers({
        institutionId: institutionId!,
        courseId: courseId!
      }),
      institution
    };
  };
