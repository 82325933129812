import Question from './Question';
import ComplaintForm from './ComplaintForm';
import questions from './questions.json';
import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import './Help.css';
import { Typography } from '@mui/material';

const Help = () => {
  return (
    <div className="help">
      <div className="help-title">¿En qué te podemos ayudar?</div>
      <div className="help-container">
        <div className="help-subtitle">Contacto</div>
        <div className="faq-buttons">
          <IconButton>
            <a href="https://wa.me/1161297377">
              <WhatsAppIcon fontSize="large" sx={{ color: '#184b76' }} />
            </a>
          </IconButton>
          <IconButton>
            <a href="https://instagram.com/estudi.clases">
              <InstagramIcon fontSize="large" sx={{ color: '#184b76' }} />
            </a>
          </IconButton>
        </div>
        <div>
          <span className="help-subtitle">Preguntas Frecuentes</span>
          {questions.map((question) => (
            <Question key={`question-${question.title}`} question={question} />
          ))}
        </div>
        <div>
          <span className="help-subtitle">Reclamos</span>
          <Typography
            variant="h6"
            component="div"
            align="left"
            sx={{
              marginLeft: '3%',
              fontSize: {
                sm: '1rem',
                xs: '0.75rem'
              }
            }}>
            ¿Tuviste un problema? Dejanos tu reclamo y te contactamos.
          </Typography>
          <ComplaintForm />
        </div>
      </div>
    </div>
  );
};

export default Help;
