import { Course } from '../../types/Course';

const fetchInstitutionCourses = async (institutionId: string): Promise<Course[]> => {
  const coursesUrl = `${process.env.REACT_APP_ESTUDI_API_URL}/estudi/web/courses?institutionId=${institutionId}`;

  try {
    const response = await fetch(coursesUrl);

    return await response.json();
  } catch (error) {
    console.error(error);

    return Promise.reject();
  }
};

export const institutionsCoursesQueryFunction = (institutionId: string) => ({
  queryKey: [institutionId, 'courses'],
  queryFn: async (): Promise<Course[]> => fetchInstitutionCourses(institutionId)
});
