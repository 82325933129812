import './ProfilePicture.css';
import classNames from 'classnames';
import Avatar from '@mui/material/Avatar';

export type ImageSize = 'large';

const ProfileImage = ({ image_url, size }: { image_url: string; size?: ImageSize }) => {
  return (
    <div className="pictureContainer">
      <Avatar
        alt="profile"
        src={image_url}
        sx={{ width: 60, height: 60 }}
        className={classNames({
          ['profileImage']: size === 'large'
        })}
      />
    </div>
  );
};

export default ProfileImage;
