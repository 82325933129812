import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
initMercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY!!, { locale: 'es-AR' });

const CheckoutModal = ({
  paymentIntentionId,
  onReady,
  onSubmit
}: {
  paymentIntentionId: string;
  onReady: any;
  onSubmit: any;
}) => {
  initMercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY!!, { locale: 'es-AR' });

  return (
    <div id="wallet_container" style={{ maxWidth: '100%' }}>
      <Wallet
        onReady={onReady}
        onSubmit={onSubmit}
        initialization={
          {
            redirectMode: 'modal',
            preferenceId: paymentIntentionId,
            marketplace: true
          } as any
        }
      />
    </div>
  );
};

export default CheckoutModal;
