import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { CreateReservation } from '../types/CreateReservation';
import { genericError, getMessageFromApiError } from '../utils/functions/getMessageFromApiError';

interface ReservationContextProps {
  isLoading: boolean;
  createReservation: (reservation: CreateReservation) => Promise<string>;
  cancelReservation: () => Promise<void>;
  clearContext: () => void;
  paymentIntentionId: string | undefined;
  paymentIntentionAmount: number | undefined;
  reservationAmount: number | undefined;
  reservationErrorMessage: string | undefined;
  reservationStatus: string | undefined;
}

const ReservationContext = createContext<ReservationContextProps>({
  isLoading: false,
  createReservation: () => Promise.reject(),
  cancelReservation: () => Promise.reject(),
  clearContext: () => {},
  paymentIntentionId: undefined,
  paymentIntentionAmount: undefined,
  reservationAmount: undefined,
  reservationErrorMessage: undefined,
  reservationStatus: undefined
});

export const useReservationContext = () => useContext(ReservationContext);

interface ReservationProviderProps {
  children: ReactNode;
}

export const ReservationProvider: React.FC<ReservationProviderProps> = ({
  children
}: ReservationProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reservationId, setReservationId] = useState<string | undefined>();
  const [paymentIntentionId, setPaymentIntentionId] = useState<string | undefined>();
  const [reservationAmount, setReservationAmount] = useState<number | undefined>();
  const [paymentIntentionAmount, setPaymentIntentionAmount] = useState<number | undefined>();
  const [reservationErrorMessage, setReservationErrorMessage] = useState<string | undefined>();
  const [reservationStatus, setReservationStatus] = useState<string | undefined>();

  const clearContext = (): void => {
    setPaymentIntentionId(undefined);
    setReservationAmount(undefined);
    setPaymentIntentionAmount(undefined);
    setReservationErrorMessage(undefined);
    setReservationId(undefined);
    setReservationStatus(undefined);
  };

  const cancelReservation = useCallback(async () => {
    if (reservationId) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_ESTUDI_API_URL}/estudi/web/reservations/${reservationId}/cancel`,
          {
            method: 'POST',
            redirect: 'follow'
          }
        );

        if (response.status === 200) {
          setReservationId(undefined);
        }
      } catch (error: unknown) {
        console.error(error);
        throw error;
      } finally {
        clearContext();
      }
    }
  }, [reservationId]);

  const pollingReservation = async (id: string) => {
    let attempts = 0;
    const maxAttempts = 120;

    while (attempts < maxAttempts) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_ESTUDI_API_URL}/estudi/web/reservations?reservationId=${id}`
        );

        const responseBody = await response.json();

        if (response.status === 200) {
          const paymentIntentionResponse = responseBody?.payment_intention;

          setPaymentIntentionId(paymentIntentionResponse?.external_id);
          setReservationAmount(responseBody?.total_amount);
          setPaymentIntentionAmount(paymentIntentionResponse?.total_amount);
          setReservationId(responseBody?.external_id);
          setReservationStatus(responseBody?.status);
          if (responseBody.status === 'confirmed' || responseBody.status === 'cancelled') {
            return;
          }
        }
      } catch (error) {
        console.error(error);
        clearContext();
      }

      attempts++;
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      await new Promise((resolve) => setTimeout(resolve, 7500));
    }

    console.error('Polling reservation confirmation max attempts reached');
    clearContext();
  };

  const createReservation = async (reservationRequest: CreateReservation): Promise<string> => {
    clearContext();

    setIsLoading(true);
    let responseBody;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_ESTUDI_API_URL}/estudi/web/reservations`,
        {
          method: 'POST',
          body: JSON.stringify(reservationRequest),
          redirect: 'follow'
        }
      );

      responseBody = await response.json();

      if (response.status === 201) {
        const paymentIntentionResponse = responseBody?.payment_intention;

        setPaymentIntentionId(paymentIntentionResponse?.external_id);
        setReservationAmount(responseBody?.total_amount);
        setPaymentIntentionAmount(paymentIntentionResponse?.total_amount);
        setReservationId(responseBody?.external_id);
        setReservationStatus(responseBody?.status);
        void pollingReservation(responseBody?.external_id);

        return paymentIntentionResponse;
      }
    } catch (error: unknown) {
      console.error(error);
      setReservationErrorMessage(genericError);
      throw error;
    } finally {
      setIsLoading(false);
    }

    const errorMessage = getMessageFromApiError(responseBody);

    console.error(errorMessage);
    setReservationErrorMessage(errorMessage);
    throw new Error(errorMessage);
  };

  return (
    <ReservationContext.Provider
      value={{
        isLoading,
        createReservation,
        cancelReservation,
        clearContext,
        paymentIntentionId,
        paymentIntentionAmount,
        reservationAmount,
        reservationErrorMessage,
        reservationStatus
      }}>
      {children}
    </ReservationContext.Provider>
  );
};

export default ReservationProvider;
