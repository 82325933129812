import ReactGA4 from 'react-ga4';

export const trackEvent = (category: string, action: string, label: string) => {
  try {
    ReactGA4.event({
      category: category,
      action: action,
      label: label
    });
    (window as any)?.fbq('track', action + '_' + category + '_' + label);
  } catch {
    // do nothing
  }
};

export const trackClickEvent = (category: string, label: string) => {
  trackEvent(category, 'click', label);
};

export const trackClickButtonEvent = (label: string) => {
  trackEvent('button', 'click', label);
};
