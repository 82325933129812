import { Await, useLoaderData, useNavigate, useRevalidator } from 'react-router-dom';
import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CalendarInfo } from '../Results/Results.types';
import TeachersCalendar from '../Calendar';
import { SelectedSlot } from '../Calendar/Calendar';
import ProfileImage from '../ProfilePicture';
import './TeacherProfile.css';
import { TeacherInfo } from '../../types/TeacherInfo';
import { Course } from '../../types/Course';
import { useTeacherSearchContext } from '../../providers/TeacherSearchProvider';
import ReservationCheckout from './ReservationCheckout/ReservationCheckout';
import CoursesSection from './CoursesSection';
import { useReservationContext } from '../../providers/ReservationProvider';
import { trackClickButtonEvent } from '../../utils/functions/trackEvent';
import Chip from '@mui/material/Chip';
import SchoolIcon from '@mui/icons-material/School';

const groupCoursesByInstitution = (courses: Course[]): Map<string, Course[]> => {
  return courses.reduce((coursesByInstitution, course) => {
    const institutionId = course?.institution?.value;

    if (!coursesByInstitution.get(institutionId!)) {
      coursesByInstitution.set(institutionId!, []);
    }
    coursesByInstitution.get(institutionId!)?.push(course);

    return coursesByInstitution;
  }, new Map<string, Course[]>());
};

const TeacherProfile = () => {
  const { teacher, calendarData } = useLoaderData() as {
    teacher: TeacherInfo;
    calendarData: CalendarInfo;
  };
  const dictatedCoursesByInstitution: Map<string, Course[]> = groupCoursesByInstitution(
    teacher.dictated_courses
  );
  const { course: searchedCourse, institutions } = useTeacherSearchContext();
  const selectedCourse: Course | undefined = teacher.dictated_courses.find(
    (c) => c.value == searchedCourse?.value
  );
  const [selectedCalendarSlot, setSelectedCalendarSlot] = useState<SelectedSlot | null>(null);
  const RESERVATION_STRING = teacher.reservation_type === 'purchase' ? 'Compra' : 'Reserva';
  const navigate = useNavigate();
  const { clearContext } = useReservationContext();
  const revalidator = useRevalidator();

  const handleCalendarReserve = (selectedSlot: any) => {
    trackClickButtonEvent('calendar_slot_confirmation');
    setSelectedCalendarSlot(selectedSlot);
    clearContext(); //FixMe: remove this clear context and fix error handling.
  };

  const handleReservationFormBack = () => {
    setSelectedCalendarSlot(null);
    revalidator.revalidate();
  };

  const handleBackToResults = () => {
    navigate(-1);
    trackClickButtonEvent('back_to_results');
  };

  return (
    <div className="teacherProfile">
      <button className="backToResults" onClick={handleBackToResults}>
        &larr; Volver a los resultados
      </button>
      <div className="teacherInfo">
        <Card>
          <CardContent sx={{ padding: '20px' }}>
            <div className="teacherCardHeader">
              <ProfileImage image_url={teacher.image_url} size="large" />
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontSize: {
                    sm: '2rem',
                    xs: '1.5rem'
                  }
                }}
                className="teacherCardName">
                {teacher.first_name} {teacher.last_name}
              </Typography>
              <div className="teacherCardPrice">
                <h4>${teacher.price_per_hour}</h4>
                <span className="teacherProfilebyHour">por hora</span>
                {teacher.reservation_type !== 'reservation_only' ? null : (
                  <span className="teacherProfilebyHour">(solo reserva)</span>
                )}
              </div>
            </div>
            {teacher.dictated_classes && teacher.dictated_classes! > 0 && (
              <div className="dictatedClasses">
                <Chip
                  icon={<SchoolIcon />}
                  label={`${teacher.dictated_classes} clases dictadas`}
                  size="small"
                  variant="outlined"
                />
              </div>
            )}
            <div className="teacherDescription">
              {teacher?.full_description ?? teacher.profile_description}
            </div>

            <CoursesSection
              selectedCourse={selectedCourse}
              dictatedCoursesByInstitution={dictatedCoursesByInstitution}
              institutions={institutions}
            />
          </CardContent>
        </Card>
      </div>
      {selectedCalendarSlot ? (
        <ReservationCheckout
          onBack={handleReservationFormBack}
          teacher={teacher}
          start={selectedCalendarSlot.start}
          end={selectedCalendarSlot.end}
          reservationType={RESERVATION_STRING}
        />
      ) : (
        <React.Suspense fallback={<p>Cargando calendario...</p>}>
          <Await
            resolve={calendarData}
            errorElement={<p>Ocurrió un error cargando el calendario!</p>}>
            <TeachersCalendar
              businessHours={{
                daysOfWeek: teacher.available_days_of_week,
                ...teacher.business_hours
              }}
              handleConfirmation={handleCalendarReserve}
              teacherExternalId={teacher.external_id}
              reservationType={RESERVATION_STRING}
            />
          </Await>
        </React.Suspense>
      )}
    </div>
  );
};

export default TeacherProfile;
