import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Question.css';

export interface QuestionType {
  readonly title: string;
  readonly description: string;
}

const Question = ({ question }: { question: QuestionType }) => {
  return (
    <div className="question">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography>{question.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{question.description}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Question;
