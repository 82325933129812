import { Outlet } from 'react-router-dom';
import './Outline.css';
import Navbar from '../Navbar';

const Outline = () => {
  return (
    <div className="outline">
      <Navbar />
      <Outlet />
    </div>
  );
};

export default Outline;
