export const dateOptions = {
  weekday: 'long',
  month: 'long',
  day: 'numeric'
} as const;

export const timeOptions = {
  hour: '2-digit',
  minute: '2-digit'
} as const;

export const formatDate = (date: Date, options: Intl.DateTimeFormatOptions | undefined) => {
  return new Intl.DateTimeFormat('es', options).format(date);
};
