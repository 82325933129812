import { defer } from 'react-router-dom';
import { teacherAdapter } from '../../utils/functions/teachers';

const ESTUDI_API_URL = process.env.REACT_APP_ESTUDI_API_URL;

export async function loader({ params }: { params: any }) {
  try {
    const teacherProfilePromise = fetch(
      `${ESTUDI_API_URL}/estudi/web/teachers/${params.teacherId}/profile`
    ).then(async (res) => {
      const response = await res.json();

      return teacherAdapter(response);
    });

    const teacherCalendarPromise = fetch(
      `${ESTUDI_API_URL}/estudi/web/teachers/${params.teacherId}/profile/calendar`
    ).then((res) => res.json());

    return defer({
      teacher: await teacherProfilePromise,
      calendarData: teacherCalendarPromise
    });
  } catch (error) {
    console.error(error);

    return defer({
      teacher: await Promise.reject(),
      calendarData: Promise.reject()
    });
  }
}
