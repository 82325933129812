import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './ByNumbers.css';
import { useEffect, useState } from 'react';
import React from 'react';
import { Skeleton } from '@mui/material';

const getReservationCount = async (fromDate: string) => {
  const myHeaders = new Headers();

  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('X-Workflow-Api-Key', process.env.REACT_APP_RESOURCES_API_KEY!);
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({
      from_date: fromDate
    })
  };

  try {
    const response = await fetch(
      'https://proxy.estudi.com.ar/?https://api.retool.com/v1/workflows/9618f525-6737-478a-8a78-884ed9421d07/startTrigger',
      requestOptions
    );

    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    console.error(error);
  }

  return undefined;
};

const ByNumbers = () => {
  const [reservationsCount, setReservationsCount] = useState<number>(0);
  const [coursesCount, setCoursesCount] = useState<number>(0);
  const [teachersCount, setTeachersCount] = useState<number>(0);
  const [studentCount, setStudentCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchReservationsCount = async () => {
      setLoading(true);
      const response = await getReservationCount('2024-04-01');

      setLoading(false);

      if (!response) {
        setError(true);

        return;
      }

      setReservationsCount(response.reservations_count);
      setCoursesCount(response.courses_count);
      setTeachersCount(response.teachers_count);
      setStudentCount(response.teachers_count);
    };

    void fetchReservationsCount();
  }, []);
  const totalReservations: number = 57226 + reservationsCount * 1;
  const totalCourses: number = coursesCount || 280;
  const totalTeachers: number = teachersCount || 84;
  const totalStudents: number = 5000 + studentCount * 1;

  const cards = [
    {
      title: 'Clases dadas',
      value: '+' + totalReservations,
      secondary: 'desde la creación de estudi'
    },
    {
      title: 'Alumnos',
      value: '+' + totalStudents,
      secondary: 'encontraron a su profe ideal con nosotros'
    },
    {
      title: 'Profesores',
      value: '' + totalTeachers,
      secondary: 'estudiantes avanzados que saben lo que necesitas'
    },
    { title: 'Materias', value: '' + totalCourses, secondary: 'y sumando nuevas constantemente!' }
  ];

  return (
    <React.Fragment>
      <div className="by-numbers">
        {cards.map((card) => (
          <Card key={card.title} className="bn-card">
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {card.title}
              </Typography>
              <Typography variant="h5" component="div">
                {loading ? <Skeleton animation="wave" /> : card.value}
              </Typography>
              <Typography variant="body2">{card.secondary}</Typography>
            </CardContent>
          </Card>
        ))}
      </div>
      {error && (
        <Typography textAlign="center" variant="body2">
          Entre 2018 y 2024
        </Typography>
      )}
    </React.Fragment>
  );
};

export default ByNumbers;
