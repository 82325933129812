import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './Navbar.css';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="sidebarTitle">
        <Link to={'/'}>estudi</Link>
      </div>
      <Link className="helpLink" to={'/help'}>
        ayuda
      </Link>
      <div className="drawer">
        <IconButton onClick={() => setDrawerOpen(true)}>
          <MenuIcon fontSize="large" sx={{ color: 'white', marginRight: '50%' }} />
        </IconButton>
        <Drawer
          anchor="top"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          sx={{
            '& .MuiPaper-root': {
              marginTop: '0px',
              paddingBottom: '10%',
              maxWidth: '100%',
              width: '100%',
              backgroundColor: 'white',
              background: '#184b76',
              color: 'white',
              alignItems: 'center',
              textAlign: 'center'
            }
          }}>
          <Fragment key="dropdown-mobile">
            <IconButton
              onClick={closeDrawer}
              sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
              <CloseIcon
                fontSize="large"
                sx={{
                  color: 'white',
                  marginRight: '4%',
                  marginTop: '8%'
                }}
              />
            </IconButton>
            <Link className="link" to={'/help'} onClick={closeDrawer}>
              ayuda
            </Link>
          </Fragment>
        </Drawer>
      </div>
    </nav>
  );
};

export default Navbar;
