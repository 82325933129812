import { useState } from 'react';
import { Modal, Typography, Box } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { sm: '50%', xs: '65%' },
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  height: '80%',
  overflowY: 'scroll',
  p: 4
};

const Terms = ({ openModal, onClose }: { openModal: boolean; onClose: any }) => {
  const [open, setOpen] = useState(openModal);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Términos y condiciones
          </Typography>
          <Typography id="modal-modal-description-1" sx={{ mt: 2 }}>
            Estudi a través de su sitio web{' '}
            <a href="https://www.estudi.com.ar/">www.estudi.com.ar</a> (en adelante el “Sitio”),
            comunica los Términos y Condiciones que regirán la relación contractual entre todo
            usuario - registrado o no - que ingrese al Sitio (en adelante el “Usuario”) y el
            propietario del Sitio (Estudi), en adelante el “Titular”. Asimismo, se informa en el
            presente la política de protección de datos de carácter personal (en adelante los “Datos
            Personales”) con el objeto que el Usuario determine en forma voluntaria si desea
            suministrar a Estudi sus datos personales en ocasión del uso del Sitio o compra de
            alguno de los productos o servicios ofrecidos por Estudi en el mismo.
          </Typography>
          <br />
          <Typography id="modal-modal-subtitle-1" variant="h6" component="h2">
            Finalidad
          </Typography>
          <Typography id="modal-modal-description-2" sx={{ mt: 2 }}>
            La finalidad del Sitio es proporcionar ayuda a quienes desean conseguir un profesor
            particular docente o alumno avanzado (en adelante el “Profesor”) para carreras de grado
            universitarias según sus necesidades para un curso o materia de grado en particular (en
            adelante el “Curso”) en el territorio de la República Argentina.
          </Typography>
          <br />
          <Typography id="modal-modal-subtitle-2" variant="h6" component="h2">
            Usuarios
          </Typography>
          <Typography id="modal-modal-description-3" sx={{ mt: 2 }}>
            Se entenderá por Usuario a toda aquella persona que navegue por el Sitio, accediendo a
            cualquiera de sus páginas o secciones, bastando con el simple acceso y/o uso de este
            Sitio, aceptando desde dicho acceso y/o uso, los términos y condiciones aquí reflejados.
            En caso de que el Usuario desee contratar alguno de los servicios ofrecidos en el Sitio,
            se aplicarán también las políticas de contratación, las cuales serán de obligatorio
            cumplimiento.
          </Typography>
          <br />
          <Typography id="modal-modal-subtitle-3" variant="h6" component="h2">
            Condiciones de uso
          </Typography>
          <Typography id="modal-modal-description-4" sx={{ mt: 2 }}>
            <b>1 - Aceptación de los términos y condiciones</b>
            <br /> La utilización del Sitio le otorga la condición de Usuario, e implica la
            aceptación completa irrevocable e incondicional de todas las cláusulas y condiciones de
            uso incluidas en las sección de Políticas de privacidad. Asimismo, declara bajo
            juramento tener 18 años de edad o más. Los padres, tutores o responsables de los menores
            de 18 años, son plenamente responsables por el uso del Sitio por parte de éstos. Si no
            estuviera conforme con todas y cada una de estas cláusulas y condiciones abstenerse de
            utilizar este Sitio. El acceso a este Sitio no supone, en modo alguno, el inicio de una
            relación comercial con el Titular.
            <br />
            <b>2 - Modificación de los Términos y condiciones</b>
            <br />
            Estudi se reserva el derecho de realizar cambios en el Sitio, en las condiciones de uso
            y en las notificaciones que correspondieren, en cualquier momento, y con la simple
            modificación del presente, comunicándose a través de publicación en el Sitio. Asimismo,
            Estudi se reserva el derecho en cualquier momento de interrumpir el servicio ofrecido,
            sin previo aviso al Usuario, en forma transitoria o definitiva, y sin necesitar
            consentimiento del Usuario.
            <br /> <b>3 - Uso de contenido del Sitio</b>
            <br />A través del Sitio, el Titular le facilita el acceso y la utilización de diversos
            contenidos que el Titular o sus colaboradores han publicado por medio de Internet. A tal
            efecto, usted está obligado y comprometido a NO utilizar cualquiera de los contenidos
            del Sitio con fines o efectos ilícitos, prohibidos en este Aviso Legal o por la
            legislación vigente, lesivos de los derechos e intereses de terceros, o que de cualquier
            forma puedan dañar, inutilizar, sobrecargar, deteriorar o impedir la normal utilización
            de los contenidos, los equipos informáticos o los documentos, archivos y toda clase de
            contenidos almacenados en cualquier equipo informático propios o contratados por el
            Titular, de otros usuarios o de cualquier usuario de Internet.
          </Typography>
          <br />
          <Typography id="modal-modal-subtitle-4" variant="h6" component="h2">
            Medidas de seguridad
          </Typography>
          <Typography id="modal-modal-description-5" sx={{ mt: 2 }}>
            Los datos personales que facilite al Titular pueden ser almacenados en bases de datos
            automatizadas o no, cuya titularidad corresponde en exclusiva al Titular, que asume
            todas las medidas de índole técnica, organizativa y de seguridad que garantizan la
            confidencialidad, integridad y calidad de la información contenida en las mismas de
            acuerdo con lo establecido en la normativa vigente en protección de datos. No obstante,
            debe ser consciente de que las medidas de seguridad de los sistemas informáticos en
            Internet no son enteramente fiables y que, por tanto el Titular no puede garantizar la
            inexistencia de virus u otros elementos que puedan producir alteraciones en los sistemas
            informáticos (software y hardware) del Usuario o en sus documentos electrónicos y
            ficheros contenidos en los mismos aunque el Titular pone todos los medios necesarios y
            toma las medidas de seguridad oportunas para evitar la presencia de estos elementos
            dañinos.
          </Typography>
          <br />
          <Typography id="modal-modal-subtitle-5" variant="h6" component="h2">
            Datos personales
          </Typography>
          <Typography id="modal-modal-description-6" sx={{ mt: 2 }}>
            Estudi ha implementado todos los procesos requeridos a los efectos de Protección de los
            Datos Personales de los Usuarios, con el objeto de evitar el mal uso, alteración, acceso
            no autorizado y/o robo de los Datos Personales suministrados por el Usuario a Estudi.
            Sin perjuicio de lo expresado en el presente, el Usuario acepta y reconoce que las
            medidas de seguridad en Internet no son inviolables. El Usuario que suministre sus datos
            personales tendrá la posibilidad de ejercer su derecho de acceso a los mismos. También
            podrá rectificar los mismos. Toda información suministrada será tratada por Estudi
            conforme a los términos previstos en la Ley Nacional de Protección de Datos Personales
            Nª 25.326. Usted puede consultar toda la información relativa al tratamiento de datos
            personales que recoge el Titular en la sección de la Política de privacidad.
          </Typography>
          <br />
          <Typography id="modal-modal-subtitle-6" variant="h6" component="h2">
            Contenidos
          </Typography>
          <Typography id="modal-modal-description-7" sx={{ mt: 2 }}>
            El Titular ha obtenido la información, el contenido multimedia y los materiales
            incluidos en el Sitio de fuentes que considera fiables, pero, si bien ha tomado todas
            las medidas razonables para asegurar que la información contenida es correcta, el
            Titular no garantiza que sea exacta, completa o actualizada. El Titular declina
            expresamente cualquier responsabilidad por error u omisión en la información contenida
            en las páginas del Sitio. Queda prohibido transmitir o enviar a través del Sitio
            cualquier contenido ilegal o ilícito, virus informáticos, o mensajes que, en general,
            afecten o violen derechos del Titular o de terceros. Los contenidos del Sitio tienen
            únicamente una finalidad informativa y bajo ninguna circunstancia deben usarse ni
            considerarse como oferta de venta, solicitud de una oferta de compra ni recomendación
            para realizar cualquier otra operación, salvo que así se indique expresamente. El
            Titular se reserva el derecho a modificar, suspender, cancelar o restringir el contenido
            del Sitio, los vínculos o la información obtenida a través del Sitio, sin necesidad de
            previo aviso. El Titular no es responsable de los daños y perjuicios que pudieran
            derivarse de la utilización de la información del Sitio.
          </Typography>
          <br />
          <Typography id="modal-modal-subtitle-8" variant="h6" component="h2">
            Propiedad intelectual e industrial
          </Typography>
          <Typography id="modal-modal-description-9" sx={{ mt: 2 }}>
            Todos los derechos están reservados. Todo acceso a este Sitio está sujeto a las
            siguientes condiciones: la reproducción, almacenaje permanente y la difusión de los
            contenidos o cualquier otro uso que tenga finalidad pública o comercial queda
            expresamente prohibida sin el consentimiento previo expreso y por escrito de Titular.
          </Typography>
          <br />
          <Typography id="modal-modal-subtitle-9" variant="h6" component="h2">
            Limitación de responsabilidad
          </Typography>
          <Typography id="modal-modal-description-10" sx={{ mt: 2 }}>
            La información y servicios incluidos o disponibles a través de este Sitio pueden incluir
            incorrecciones o errores tipográficos. De forma periódica el Titular incorpora mejoras
            y/o cambios a la información contenida y/o los servicios que puede introducir en
            cualquier momento. El Titular no declara ni garantiza que los servicios o contenidos
            sean interrumpidos o que estén libres de errores, que los defectos sean corregidos, o
            que el servicio o el servidor que lo pone a disposición estén libres de virus u otros
            componentes nocivos sin perjuicio de que el Titular realiza todos los esfuerzos en
            evitar este tipo de incidentes. El Titular declina cualquier responsabilidad en caso de
            que existan interrupciones o un mal funcionamiento de los Servicios o contenidos
            ofrecidos en Internet, cualquiera que sea su causa. Asimismo, el Titular no se hace
            responsable por caídas de la red, pérdidas de negocio a consecuencia de dichas caídas,
            suspensiones temporales de fluido eléctrico o cualquier otro tipo de daño indirecto que
            te pueda ser causado por causas ajenas a el Titular. Antes de tomar decisiones y/o
            acciones con base a la información incluida en el Sitio, el Titular le recomienda
            comprobar y contrastar la información recibida con otras fuentes.
          </Typography>
          <br />
          <Typography id="modal-modal-subtitle-10" variant="h6" component="h2">
            Derecho de exclusión
          </Typography>
          <Typography id="modal-modal-description-11" sx={{ mt: 2 }}>
            El Titular se reserva el derecho a denegar o retirar el acceso al Sitio y los servicios
            ofrecidos sin necesidad de preaviso, a instancia propia o de un tercero, a aquellos
            usuarios que incumplan cualquiera de las condiciones de este Aviso Legal.
          </Typography>
          <br />
          <Typography id="modal-modal-subtitle-11" variant="h6" component="h2">
            Garantía de resultados
          </Typography>
          <Typography id="modal-modal-description-12" sx={{ mt: 2 }}>
            El Titular no se hará responsable por el éxito o fracaso de los usuarios en la
            aplicación de la información que reciban del Sitio o de los servicios que se ofrecen. El
            Usuario será el único responsable por la aplicación de lo aprendido con el material
            suministrado por el Profesor que dicte clase para un Curso. Los contenidos que se
            suministran, se imparten de manera informativa, no queriendo en ningún momento sustituir
            alguna asesoría profesional personalizada que el usuario, cliente, estudiante o
            participante requiera en un tema determinado, y no se hará responsable por los problemas
            que pueda tener éste por un uso, correcto o no, de la información suministrada o por no
            tomar en cuenta problemas de salud, ideológicos, económicos o de cualquier otra índole
            que pudieran ser afectados por el contenido que se encuentre en de forma gratuita o no
            expuesto dentro del Sitio.
          </Typography>
          <br />
          <Typography id="modal-modal-subtitle-12" variant="h6" component="h2">
            Formas de contacto
          </Typography>
          <Typography id="modal-modal-description-13" sx={{ mt: 2 }}>
            Si un usuario desea contactar al Titular podrá enviar un correo electrónico a la
            dirección <a href="mailto:info@estudi.com.ar">info@estudi.com.ar</a>
          </Typography>
          <br />
          <Typography id="modal-modal-subtitle-13" variant="h6" component="h2">
            Políticas de privacidad
          </Typography>
          <Typography id="modal-modal-description-14" sx={{ mt: 2 }}>
            Los datos personales serán utilizados para mantener el contacto y mantenerte al día de
            las actualizaciones, para enviarte la respuesta a la cuestión que solicitas al rellenar
            el formulario de contacto, realizar encuestas de satisfacción y opinión sobre el Sitio y
            los temas tratados en ella, o para informarte de las ofertas. Puede usted ejercer los
            derechos de acceso, rectificación o supresión de los datos personales facilitados
            voluntariamente por usted a través de algún formulario web, dirigiéndose a
            <a href="mailto:info@estudi.com.ar">info@estudi.com.ar</a>. Puede dejar de recibir estos
            emails en cualquier momento haciendo click en el link del pie de nuestros emails. Usamos
            SendGrid como nuestra plataforma de marketing. Al clickear debajo a suscribir, acepta
            que esta información sea transferida a SendGrid para su procesamiento. Aprenda más sobre
            las políticas de privacidad de SendGrid{' '}
            <a href="https://sendgrid.com/policies/privacy/">aquí</a>.
          </Typography>
          <br />
          <Typography id="modal-modal-subtitle-14" variant="h6" component="h2">
            Políticas de contratación
          </Typography>
          <Typography id="modal-modal-description-15" sx={{ mt: 2 }}>
            La compra de cualquier producto o servicio publicado a través del Sitio se encuentra
            sujeta a disponibilidad de stock, de la aprobación de identidad del Usuario, de la
            imputación correcta de la tarjeta de crédito o valores transferidos y a la aprobación
            por parte del Usuario de estos Términos y Condiciones.
            <br />
            <b>1 - Medios de pago</b>
            <br />
            Los pagos podrán realizarse a través de la plataforma de pago MercadoPago. La
            confirmación de la compra con tarjeta de crédito estará sujeta a la autorización del
            emisor de la misma. Todos los medios de pago están sujetos a que el importe sea
            debidamente acreditado y/o verificado. IMPORTANTE: Estudi no mantiene un vínculo
            asociativo con las corporaciones titulares de los sistemas de pago autorizados ni
            integra un grupo económico junto a las mismas. La utilización de los servicios de pagos
            ofrecidos por tales entidades se realiza por cuenta y riesgo del usuario o consumidor,
            no pudiendo en ningún caso imputarse a Estudi responsabilidad por los hechos u omisiones
            de tales entidades. Estudi no accede a los datos de las tarjetas de crédito u otros
            medios de pago empleados por el usuario, de modo tal que no garantiza que el
            tratamiento, almacenamiento y utilización de tales datos sea adecuado y se ajuste a las
            leyes de protección de datos personales (en Argentina, ley nro. 25326) y/o de defensa de
            los derechos del usuario o consumidor (en Argentina, ley 24240) y/o de tarjetas de
            crédito (en Argentina, Ley 25065).
            <br />
            <b>2 - Proceso de compra</b>
            <br />
            El resultado de la operación le será informado al Usuario y las operaciones exitosas
            podrán o no generar aviso visual en el Sitio, como resultado de la misma. A su vez, en
            casos exitosos podrá recibir correo electrónico informando el evento del Curso con los
            datos del Profesor.
            <br />
            <b>3 - Cancelaciones y/o devoluciones</b>
            <br />
            Para cancelar compras de productos o servicios en el Sitio, así como para solicitar una
            devolución deberá solicitarse por los canales de contacto del Sitio. El Titular se
            reserva el derecho para evaluar y decidir si acepta las solicitudes de cancelación o
            devolución en función de la evidencia y el caso presentado.
          </Typography>
          <br />
          <Typography id="modal-modal-subtitle-15" variant="h6" component="h2">
            Seguimiento de actividad
          </Typography>
          <Typography id="modal-modal-description-16" sx={{ mt: 2 }}>
            El Usuario del Sitio conoce y acepta que Estudi podrá utilizar un sistema de seguimiento
            mediante la utilización de cookies. Las Cookies son pequeños archivos que se instalan en
            el disco rígido, con una duración limitada en el tiempo que ayudan a personalizar los
            servicios y a activar ciertas funcionalidades que sólo podrán estar disponibles mediante
            el empleo de Cookies. Se utilizará información obtenida mediantes las Cookies para
            analizar las páginas navegadas por el Usuario, las búsquedas realizadas, mejorar las
            iniciativas comerciales y promocionales, mostrar publicidad o promociones, banners de
            interés, perfeccionar la oferta de contenidos y artículos; también para promover y hacer
            cumplir las reglas y seguridad del sitio.
          </Typography>
          <br />
          <Typography id="modal-modal-subtitle-16" variant="h6" component="h2">
            Avisos publicitarios
          </Typography>
          <Typography id="modal-modal-description-17" sx={{ mt: 2 }}>
            Cuando el Usuario hace “click” en avisos publicitarios o links de terceros e ingresa en
            otros sitios que no pertenecen a Estudi estará sujeto a los Términos y Condiciones de
            dichos sitios. El Usuario deberá leer detenidamente sus políticas de acceso y uso.
            Estudi no garantiza la legalidad, actualidad, calidad ni utilidad de los contenidos,
            operaciones e informaciones que se comuniquen, reproduzcan y/o realicen en sitios
            enlazados de terceros ni la ausencia de nocividad de tales contenidos o servicios, por
            lo que el Usuario exime de toda responsabilidad a Estudi por los contenidos incluidos en
            los referidos sitios o los servicios que en ellos se brindan o promocionan.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Terms;
