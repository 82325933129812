import { useSearchParams } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import './PaymentOutcome.css';

const PaymentSuccess = () => {
  return (
    <div className="paymentTitle">
      <CheckCircleOutlineIcon sx={{ fontSize: '140px' }} />
      <h1>¡Felicitaciones!</h1>
      <div className="paymentSummary">
        <p>¡Gracias por elegirnos para tus clases particulares!</p>
        <p>
          Te llegará un mail confirmando el pago de tu clase (recordá revisar SPAM). Además, en las
          proximas horas vamos crearte un grupo de WhatsApp entre vos y tu profe particular para que
          puedan hablar.
        </p>
        <p className="paymentContact">
          Ante cualquier duda accedé a nuestra <a href="/help">página de ayuda</a>.
        </p>
      </div>
    </div>
  );
};

const PaymentError = () => {
  return (
    <div className="paymentTitle">
      <WarningIcon sx={{ fontSize: 140 }} />
      <h1>Hubo un problema</h1>
      <div>Lo lamentamos. Intentá nuevamente o contactános.</div>
      <p className="paymentContact">
        Ante cualquier duda accedé a nuestra <a href="/help">página de ayuda</a>.
      </p>
      <NavHashLink smooth to="/help#complaintForm">
        ¿Tuviste un problema? Dejanos tu reclamo
      </NavHashLink>
    </div>
  );
};

const PaymentOutcome = () => {
  let [searchParams] = useSearchParams();

  const isApproved = searchParams.get('status') === 'approved';

  return <div className="payment">{isApproved ? <PaymentSuccess /> : <PaymentError />}</div>;
};

export default PaymentOutcome;
