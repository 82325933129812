import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Course } from '../../../types/Course';
import { Institution } from '../../../types/Institution';
import './CoursesSection.css';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3, maxHeight: '200px', overflowY: 'auto' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CoursesSection = ({
  selectedCourse,
  dictatedCoursesByInstitution,
  institutions
}: {
  selectedCourse: Course | undefined;
  dictatedCoursesByInstitution: Map<string, Course[]>;
  institutions: Institution[] | undefined;
}) => {
  const [value, setValue] = React.useState(0);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  let dictatedCoursesByInstitutionComponents: JSX.Element[] = [];

  dictatedCoursesByInstitution.forEach((courses: Course[], institutionId: string) => {
    dictatedCoursesByInstitutionComponents?.push(
      <React.Fragment key={institutionId}>
        <p>{institutions?.filter((i) => i.value === institutionId)[0].label}</p>
        <ul className="dictatedCourses">
          {courses
            .filter((c) => c.value != selectedCourse?.value)
            .map((course: Course) => (
              <li key={course.label} className="teacherCourses">
                <span>{course.label}</span>
              </li>
            ))}
        </ul>
      </React.Fragment>
    );
  });

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {selectedCourse && <Tab label="MATERIA SELECCIONADA" {...a11yProps(0)} />}
          <Tab
            label={selectedCourse ? 'OTRAS MATERIAS' : 'MATERIAS DICTADAS'}
            {...a11yProps(selectedCourse ? 1 : 0)}
          />
        </Tabs>
      </Box>
      {selectedCourse && (
        <CustomTabPanel value={value} index={0}>
          <p>{selectedCourse.institution?.label}</p>
          <span className="teacherSelectedCourse">{selectedCourse.label}</span>
          {selectedCourse?.notes && (
            <p>
              <br />
              <b>Aclaraciones: </b>
              {selectedCourse?.notes}
            </p>
          )}
        </CustomTabPanel>
      )}
      <CustomTabPanel value={value} index={selectedCourse ? 1 : 0}>
        {dictatedCoursesByInstitutionComponents.flat()}
      </CustomTabPanel>
    </div>
  );
};

export default CoursesSection;
