import './ErrorPage.css';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
  return (
    <div id="error-page" className="errorPage">
      <h1>Oops!</h1>
      <p>Parece que ha ocurrido un error.</p>
      <Link to={'/'}>
        <button className="backHomeButton">Volver al home</button>
      </Link>
    </div>
  );
}
