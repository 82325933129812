import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface StudentUserContextProps {
  user: {
    email: string;
    phoneNumber: string;
    name: string;
    lastname: string;
  };
  updateUser: (user: Partial<StudentUserContextProps['user']>) => void;
}

const defaultState = {
  user: {
    email: '',
    phoneNumber: '',
    name: '',
    lastname: ''
  },
  updateUser: () => {}
};

const StudentUserContext = createContext<StudentUserContextProps>(defaultState);

export const useStudentUserContext = () => useContext(StudentUserContext);

interface UserProviderProps {
  children: ReactNode;
}

export const StudentUserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState(defaultState.user);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const updateUser = (newUserData: Partial<StudentUserContextProps['user']>): void => {
    setUser((current) => ({ ...current, ...newUserData }));
    localStorage.setItem('user', JSON.stringify(user));
  };

  return (
    <StudentUserContext.Provider value={{ user, updateUser }}>
      {children}
    </StudentUserContext.Provider>
  );
};

export default StudentUserProvider;
