const apiErrorCodeToErrorMessage = new Map([
  ['invalid_student.email', 'El email es invalido'],
  ['teacher_event_creation_error', 'El horario no esta disponible'],
  ['reservation_creation_error', 'Error con MercadoPago, vuelve a intentar más tarde']
]);

export const genericError = 'Lo sentimos, ha ocurrido un error.';

export const getMessageFromApiError = (apiError: any): string => {
  if (!apiError.code) {
    return genericError;
  }
  const extractedErrorMessage = apiErrorCodeToErrorMessage.get(apiError.code);

  return extractedErrorMessage ?? genericError;
};
