import React, { useState } from 'react';
import { Alert, Button, TextField } from '@mui/material';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MuiFileInput } from 'mui-file-input';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Moment } from 'moment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import './TeacherRequest.css';
import { trackClickButtonEvent } from '../../utils/functions/trackEvent';

const ACCEPTED_CONTENT_TYPES: string[] = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.apple.iwork.pages',
  'application/vnd.apple.iwork.numbers',
  'application/vnd.apple.iwork.key',
  'application/vnd.apple.iwork.template',
  'application/vnd.apple.template',
  'image/jpeg',
  'image/png',
  'image/webp',
  'image/gif',
  'text/plain'
];

const TeacherRequest = () => {
  const [name, setName] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
  const [institution, setInstitution] = useState<string | undefined>(undefined);
  const [course, setCourse] = useState<string | undefined>(undefined);
  const [context, setContext] = useState<string | undefined>(undefined);
  const [expiration, setExpiration] = useState<Moment | undefined>(undefined);
  const [content, setContent] = useState<File | null>(null);
  const [waiting, setWaiting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<boolean>(false);
  const [requestSent, setRequestSent] = useState<boolean>(false);

  const handlePhoneNumberChange = (value: any) => {
    setPhoneNumberError(!matchIsValidTel(value));
    setPhoneNumber(value);
  };
  const handleContentChange = (newValue: File | null) => {
    setContent(newValue);
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    trackClickButtonEvent('send_teacher_request');
    setApiError(false);
    setWaiting(true);

    const formdata = new FormData();

    formdata.append('content', content!, content?.name);
    formdata.append('requester_name', name!);
    formdata.append('requester_email', email!);
    formdata.append('requester_phone_number', phoneNumber!);
    formdata.append('institution', institution!);
    formdata.append('course', course!);

    formdata.append('payload', context!);
    if (expiration) {
      formdata.append('expiration_date', expiration.toISOString());
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    try {
      const response = await fetch(
        'https://api.estudi.com.ar/estudi/web/teachers/request',
        requestOptions
      );

      if (response.ok) {
        setRequestSent(true);
      } else {
        setApiError(true);
      }
    } catch (error) {
      console.error(error);
      setApiError(true);
    } finally {
      setWaiting(false);
    }
  };

  if (requestSent) {
    return (
      <div className="teacherRequestSuccess">
        <div className="teacherRequestSuccessTitle">
          <CheckCircleOutlineIcon sx={{ fontSize: '50px', marginTop: '10%' }} />
          <h1>¡Felicitaciones!</h1>
          <div className="teacherRequestSuccessSummary">
            <p>¡Gracias por elegirnos para tus clases particulares!</p>
            <p>Te contactaremos a la brevedad para ayudarte con tu pedido</p>
            <p className="teacherRequestSuccessContact">
              Ante cualquier duda accedé a nuestra <a href="/help">página de ayuda</a>.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="teacherRequest">
      <div className="teacherRequestTitle">Encontremos tu profe ideal</div>
      <div className="teacherRequestContainer">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <form
            className="teacherRequestForm"
            id="teacherRequestForm"
            name="teacher-request-form"
            acceptCharset="utf-8"
            onSubmit={handleSubmit}>
            {phoneNumberError && <Alert severity="error">Teléfono invalido</Alert>}
            {apiError && <Alert severity="error">Algo salio mal</Alert>}
            <TextField
              id="requester_name"
              key="requester_name"
              label="Nombre"
              placeholder="Nombre"
              variant="outlined"
              margin="dense"
              type="text"
              name="requester_name"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              required
            />
            <TextField
              id="requester_email"
              key="requester_email"
              label="Email"
              placeholder="Email"
              variant="outlined"
              margin="dense"
              type="email"
              name="requester_email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              required
            />
            <MuiTelInput
              id="requester_phone_number"
              key="requester_phone_number"
              label="Teléfono"
              placeholder="Teléfono"
              variant="outlined"
              name="requester_phone_number"
              margin="dense"
              forceCallingCode
              disableFormatting
              required
              value={phoneNumber}
              defaultCountry="AR"
              onChange={handlePhoneNumberChange}
            />
            <TextField
              id="institution"
              key="institution"
              label="Universidad"
              placeholder="Institución / universidad"
              variant="outlined"
              margin="dense"
              type="text"
              name="institution"
              value={institution}
              onChange={(e: any) => setInstitution(e.target.value)}
              required
            />
            <TextField
              id="course"
              key="course"
              label="Materia"
              placeholder="Curso / materia"
              variant="outlined"
              margin="dense"
              type="text"
              name="course"
              value={course}
              onChange={(e: any) => setCourse(e.target.value)}
              required
            />
            <TextField
              id="context"
              key="context"
              multiline
              rows={6}
              label="Contanos más"
              placeholder="¿Cómo describirías tu situación con la materia? ¿Qué creés que necesitas?, ¿Para qué carrera?, ¿Con qué frecuencia?"
              variant="outlined"
              margin="dense"
              type="text"
              name="context"
              value={context}
              onChange={(e: any) => setContext(e.target.value)}
              required
            />
            <MuiFileInput
              label="Contenido de referencia"
              helperText="Ej: una guia, un exámen, etc"
              inputProps={{ accept: ACCEPTED_CONTENT_TYPES.join(', ') }}
              InputProps={{
                startAdornment: <AttachFileIcon />
              }}
              variant="outlined"
              required
              margin="dense"
              getSizeText={(value: File | null) =>
                value && value?.size! >= 30 * 1024 * 1024 ? 'Muy grande, debe ser menor a 30MB' : ''
              }
              value={content}
              onChange={handleContentChange}
              hideSizeText
            />
            <DatePicker
              label="Fecha limite"
              slotProps={{
                textField: {
                  helperText: '(Opcional) Ej: fecha en la que rendis el examen'
                }
              }}
              sx={{ marginTop: '1%', marginBottom: '1%' }}
              value={expiration}
              format="DD.MM.YYYY"
              disablePast
              onChange={setExpiration as any}
            />
            <div className="teacherRequestFormButton">
              <Button
                type="submit"
                variant="contained"
                disabled={
                  !!phoneNumberError ||
                  !name ||
                  !email ||
                  !phoneNumber ||
                  !institution ||
                  !course ||
                  !content ||
                  waiting
                }>
                Enviar
              </Button>
            </div>
          </form>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default TeacherRequest;
